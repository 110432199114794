import { reduce, range, remove } from 'lodash';
const ABC = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const NUMS = '0123456789';
export const RandonString = (_range: number) => {
  const characters = ABC + NUMS;
  const charactersLength = characters.length;
  return range(_range).reduce(
    (acum: string) => acum + characters.charAt(Math.floor(Math.random() * charactersLength)),
    '',
  );
};
export const RandonAlphaString = (_range: number) => {
  const characters = ABC;
  const charactersLength = characters.length;
  return range(_range).reduce(
    (acum: string) => acum + characters.charAt(Math.floor(Math.random() * charactersLength)),
    '',
  );
};

export class Random {
  private seedArray!: number[];
  private inext!: number;
  private inextp!: number;
  constructor(seed: number = NaN) {
    this.setSeed(seed);
  }
  public setSeed(_seed: number = NaN) {
    const len = 55;
    let seed = _seed | this.milliseconds();
    this.seedArray = Array(len + 1).fill(0);
    const num = seed == -2147483648 ? 2147483647 : Math.abs(seed);
    let num2 = 161803398 - num;
    let num3 = 1;
    this.seedArray[len] = num2;
    range(len - 1).forEach((i: number) => {
      const num4 = (21 * (i + 1)) % len;
      this.seedArray[num4] = num3;
      num3 = num2 - num3;
      num3 < 0 && (num3 += 2147483647);
      num2 = this.seedArray[num4];
    });
    for (let j = 1; j < 5; j++)
      for (let k = 1; k < len + 1; k++) {
        this.seedArray[k] -= this.seedArray[1 + ((k + 30) % len)];
        this.seedArray[k] < 0 && (this.seedArray[k] += 2147483647);
      }
    this.inext = 0;
    this.inextp = 21;
    seed = 1;
    return this;
  }
  private milliseconds(): number {
    const str = Date.now().toString();
    return +str.substr(str.length - 6);
  }
  private internalSample(): number {
    let num = this.inext;
    let num2 = this.inextp;
    ++num >= 56 && (num = 1);
    ++num2 >= 56 && (num2 = 1);
    let num3 = this.seedArray[num] - this.seedArray[num2];
    num3 == 2147483647 && num3--;
    num3 < 0 && (num3 += 2147483647);
    this.seedArray[num] = num3;
    this.inext = num;
    this.inextp = num2;
    return num3;
  }
  private Sample(): number {
    return this.internalSample() * 4.6566128752457969e-10;
  }
  private getSampleForLargeRange() {
    const num = this.internalSample();
    return ((num % 2 == 0 ? num : -num) + 2147483646.0) / 4294967293.0;
  }
  public next(minValue: number, maxValue: number): number {
    if (minValue == maxValue) return minValue;
    const num = maxValue - minValue;
    return num <= 2147483647
      ? parseInt((this.Sample() * num + minValue).toFixed(0))
      : this.getSampleForLargeRange() * num + minValue;
  }
  public nextDouble(): number {
    return this.Sample();
  }
  public nextBytes(buffer: number[]): number[] {
    for (var i = 0; i < buffer.length; i++) buffer[i] = this.internalSample() % 256;
    return buffer;
  }
}
export const GenerateRandomStringFromMongoId = (_id: string, _range: number) => {
  const splitted = _id.split('');
  const numbers = remove(splitted, ele => +ele >= 0 && +ele <= 9);
  const r = new Random(+numbers.join(''));
  const characters = ABC;
  const charactersLength = characters.length;
  return range(_range).reduce((acum: string) => {
    const value = r.next(0, 1000000);
    return acum + characters.charAt(Math.floor((value / 1000000) * charactersLength));
  }, '');
};
