import { Injectable, signal } from '@angular/core';
import { RandonAlphaString } from '@libs/functionals/random.functionals';
import { BONUS_STATUS, BONUS_TYPE, CURRENCY, IBonus, IPromCode } from '@libs/interfaces';
import { MapResponseData } from '@libs/rxjs/operators.rxjs';
import { Observable, of, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectBonus } from '@libs/store/resources/selectors';
import { ApiService } from '../api.service';

@Injectable({ providedIn: 'root' })
export class BonusService {
  bonus = signal<IBonus[]>([]);
  constructor(
    public api: ApiService,
    private store: Store,
  ) {}
  /**
   * Checks if the user has made their first deposit.
   * @returns {Observable<any>} An observable that emits the response data.
   */
  isFirstDeposit() {
    return this.api.get('deposits/isFirstDeposit').pipe(MapResponseData);
  }

  /**
   * Creates a welcome bonus for the user.
   * @param {Object} params - The parameters for creating the welcome bonus.
   * @param {BONUS_TYPE} params.bonus - The type of bonus.
   * @param {string} params.user_id - The user ID.
   * @returns {Observable<IPromCode>} An observable that emits the created promo code.
   */
  createWelcomeBonus({ bonus, user_id }): Observable<IPromCode> {
    const promoCode: IPromCode = {
      user_id: user_id,
      amount: null,
      percent: bonus == BONUS_TYPE.BEMVINDO_100 ? 100 : 50,
      code: RandonAlphaString(6),
      currency: CURRENCY.BRL,
      bonus: bonus,
      status: BONUS_STATUS.PENDING,
      timestamp: Date.now(),
    };
    return this.api.post(`bonus/create`, promoCode).pipe(MapResponseData);
  }

  /**
   * Fetches the pending promo codes.
   * @returns {Observable<IPromCode[]>} An observable that emits the list of pending promo codes.
   */
  fetchPendingPromocode(): Observable<IPromCode[]> {
    return this.api.get('bonus/promo-code/pending').pipe(MapResponseData);
  }

  /**
   * Fetches the user's promo codes.
   * @returns {Observable<IPromCode[]>} An observable that emits the list of user's promo codes.
   */
  fetchUserPromos(): Observable<IPromCode[]> {
    return this.api.get(`bonus/user/promos`).pipe(MapResponseData);
  }

  /**
   * Fetches the base promos.
   * @returns {Observable<IBonus[]>} An observable that emits the list of base promos.
   */
  basePromos(): Observable<IBonus[]> {
    if (this.bonus().length > 0) {
      return of(this.bonus());
    }
    return this.api.get(`bonus/base`).pipe(
      MapResponseData,
      tap(bonus => this.bonus.set(bonus)),
    );
  }

  /**
   * Checks if cashback is available for the user.
   * @returns {Observable<any>} An observable that emits the response data.
   */
  checkIfCashbackAvailable() {
    return this.api.get(`bonus/user/cashback/available`).pipe(MapResponseData);
  }

  /**
   * Selects the bonus from the store.
   * @returns {Observable<any>} An observable that emits the selected bonus.
   */
  selectBonus() {
    return this.store.select(selectBonus);
  }
}
